.react-tagsinput {
  font-size: 12px;
  position: relative;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 0px 10px;
  outline: none;
  width: 100%;
  min-height: 30px;
  line-height: 28px;
}

.react-tagsinput--focused {
  border-color: #408eba;
}

.react-tagsinput-tag {
  background-color: #63a3c9;
  border-radius: 2px;
  border: 1px solid #408eba;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 1px 5px;
  line-height: 20px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  width: 80px;
}

.react-tagsinput-input::-webkit-input-placeholder {
  color: #ccc;
  font-style: italic;
}

.react-tagsinput-input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
}

.react-tagsinput-input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
}

.react-tagsinput-input::-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
}

.react-tagsinput-input::placeholder {
  color: #ccc;
  font-style: italic;
}

.borderRadius0 {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  height: 100%;
}

.borderRadiusR {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
}
